<template>
  <div class="duration-500 ease-out transition-all" :class="componentClass">
    <slot />
  </div>
</template>

<script>
import { isMobile } from '../mixins/isMobile';

export default {
  mixins: [isMobile],

  props: {
    disableEffect: {
      type: Boolean,
      default: false,
    },

    inClass: {
      type: String,
      default: '',
    },

    outClass: {
      type: String,
      default: '',
    },

    threshold: {
      type: Number,
    },
  },

  data() {
    return {
      observer: null,
      visible: false,
      observationOptions: {
        root: null,
        threshold: 0.25,
      },
    };
  },

  computed: {
    componentClass() {
      if (this.disableEffect) return '';

      return this.visible ? this.inViewClass : this.outViewClass;
    },

    inViewClass() {
      return `opacity-100 ${this.inClass}`;
    },

    outViewClass() {
      return `opacity-0 ${this.outClass}`;
    },
  },

  watch: {
    visible(newValue) {
      if (!newValue) return;

      this.$emit('visible', {
        status: true,
        component: this.$el,
      });

      this.observer.unobserve(this.$el);
      this.observer.disconnect();
    },

    threshold: {
      handler(newValue) {
        this.observationOptions.threshold = newValue;
      },
      immediate: true,
    },
  },

  created() {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        this.visible = entry.isIntersecting;
      });
    }, this.observationOptions);
  },

  mounted() {
    this.observer.observe(this.$el);
  },

  beforeUnmount() {
    this.observer.disconnect();
  },
};
</script>
