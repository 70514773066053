<template>
  <li
    class="flex flex-col justify-center items-center gap-[10px] sm:gap-5 md:gap-8"
  >
    <img :src="imageSrc" />
    <p class="text-lg lg:text-xl text-center mx-4 sm:mx-0">
      {{ product.title }}
    </p>
  </li>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
});
const imageSrc = computed(
  () =>
    new URL(
      `/public/theme/brochure/images/products/${props.product.image}.avif`,
      import.meta.url
    )
);
</script>
