<template>
  <HomePageHero
    :text="heroText"
    :subtext="heroSubtext"
    :images="images"
    :hero-buttons="heroButtons"
  />
  <OLParagraphCard :paragraph="paragraph" />
  <ShowcaseSection
    :heading="planningHeading"
    :subheading="planningSubheading"
    :features="planningFeatures"
  />
  <ImageSection
    :image="sectionImage.image"
    :figure-caption="sectionImage.figureCaption"
    :image-text-1="sectionImage.imageText1"
    :image-text-2="sectionImage.imageText2"
  />

  <ShowcaseSection
    class="mt-2 md:mt-0"
    :is-dark="true"
    :features="supportFeatures"
    :heading="supportHeading"
    :subheading="supportSubheading"
  />
  <OLImageStory
    column-reversed
    class="!pb-0"
    :title="firstImageSection.title"
    :body="firstImageSection.body"
    :image="firstImageSection.image"
    :figure-caption="firstImageSection.imageText"
    row-reversed
  />

  <OLImageStory
    column-reversed
    :title="secondImageSection.title"
    :body="secondImageSection.body"
    :image="secondImageSection.image"
    :figure-caption="secondImageSection.imageText"
  />

  <sliding-text :sliding-paragraphs="slidingParagraphs" />
  <OLTrustpilotReviews />
  <OLFaqs :faqs="faqs" :heading="faqsHeading" />
  <SectionCta class="" />
</template>
<script setup>
import HomePageHero from '@components/Brochure/homePage/HomePageHero.vue';
import SectionCta from '@components/Footer/SectionCta.vue';
import OLFaqs from '@components/OLFaqs.vue';
import OLTrustpilotReviews from '@components/Brochure/OLTrustpilotReviews.vue';
import SlidingText from '@components/Brochure/SlidingText.vue';
import OLParagraphCard from '@components/Brochure/OLParagraphCard.vue';
import ShowcaseSection from '@components/Brochure/homePage/ShowcaseSection.vue';
import {
  HeartIcon,
  PencilIcon,
  ShieldCheckIcon,
  GiftIcon,
  DocumentTextIcon,
  PhoneIcon,
  ScaleIcon,
} from '@heroicons/vue/24/outline/index.js';
import OLImageStory from '@components/OLImageStory.vue';
import ImageSection from '@components/Brochure/homePage/ImageSection.vue';

const props = defineProps({
  faqs: {
    type: Array,
    required: true,
  },
});
const faqsHeading =
  'Death comes with all sorts of questions. Existential, emotional, logistical. Whatever questions you have, we can stretch apart together.';

const sectionImage = {
  image: new URL(
    '/public/theme/brochure/images/hero_images/willwriting_hero.avif',
    import.meta.url
  ),
  alt: 'Laura, Head of Legal and her late Grandmother',
  figureCaption: 'Laura, Head of Legal and her late Grandmother, Mamá Inés',
  imageText1: 'Death can come between us.',
  imageText2: 'Or it can bring us together.',
};

const heroButtons = [
  { text: 'Plan for death', linkTo: route('estate-planning') },
  { text: 'Support after loss', linkTo: route('bereavement') },
];

const heroText = 'What will you leave behind?';
const heroSubtext =
  'This is the place to plan for death and find\nsupport after a loss.';
const images = [
  {
    src: new URL('/public/theme/brochure/videos/video5.mp4', import.meta.url),
  },
  {
    src: new URL('/public/theme/brochure/videos/video1.mp4', import.meta.url),
  },
  {
    src: new URL('/public/theme/brochure/videos/video4.mp4', import.meta.url),
  },
  {
    src: new URL('/public/theme/brochure/videos/video2.mp4', import.meta.url),
  },
  {
    src: new URL('/public/theme/brochure/videos/video3.mp4', import.meta.url),
  },
];

const slidingParagraphs = [
  {
    text: 'What secret don’t you want to take\nto your grave?',
  },
  {
    text: 'If you could relive one memory, what\nwould it be?',
    textColourCss: 'text-ol-secondary-200',
  },
  {
    text: 'What should everyone wear at your\nfuneral?',
    textColourCss: 'text-ol-secondary-200',
  },
  {
    text: 'What songs are on the playlist of\nyour life?',
    textColourCss: 'text-ol-secondary-200',
  },
  {
    text: 'In a sentence, how do you want to be remembered?',
    textColourCss: 'text-ol-secondary-200',
  },
];

const firstImageSection = {
  title: 'Wills, life insurance, bereavement support',
  body: "When most people think about planning for death they think about these cold legal processes. We're here to turn them into something much more human.",
  image: new URL(
    '/public/theme/brochure/images/staffFamily/heatherAndGrandad.avif',
    import.meta.url
  ),
  imageText: 'Our Software Developer Heather, and her late Uncle ',
};

const secondImageSection = {
  title: 'Share more than money',
  body: 'Leave voice notes, recipes. Don’t rush to get it over with: add to it as life changes. Create it in conversation with friends and family. Start a legacy that connects you while you’re here. And after you’re gone.',
  image: new URL(
    '/public/theme/brochure/images/staffFamily/imogenFamily.avif',
    import.meta.url
  ),
  imageText: 'Our Senior Product Manager, Imogen, and her late Grandparents',
};

const planningHeading = 'Planning for death';
const planningSubheading =
  "We're here to make giving the gift of a back up plan to the people you love as easy as possible.";
const planningFeatures = [
  {
    name: 'Will Writing',
    description:
      'Write a will that is tailored to you and protects the people you love - online, over the phone or face to face.',
    href: route('will-writing'),
    buttonText: 'Start your will',
    icon: PencilIcon,
  },
  {
    name: 'Life Insurance',
    description:
      'Our online life insurance allows you to protect the people you love without the need for a medical exam.',
    href: route('life-insurance'),
    buttonText: 'Get a quote',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Lasting Power of Attorney',
    description:
      "Get expert support when you choose someone you trust to make decisions on your behalf, if you're ever not able to.",
    href: route('lasting-power-of-attorney'),
    buttonText: 'Start your LPA',
    icon: DocumentTextIcon,
  },
  {
    name: 'Legacy Box',
    description:
      'Share something priceless and give your loved ones the tools to take on the world without you.',
    href: route('fwig'),
    buttonText: 'Create a legacy',
    icon: GiftIcon,
  },
];
const supportHeading = 'Finding support after a loss';
const supportSubheading =
  "Here to help you manage the chaos that comes with losing someone.  We can't make loss easy. But we can make it easier.";
const supportFeatures = [
  {
    name: 'Probate',
    description:
      "We're here to help you with the practical, personal and emotional challenges that come after the death of someone you love.",
    href: route('probate'),
    buttonText: 'Get a quote',
    icon: ScaleIcon,
  },
  {
    name: 'Support Hubs',
    description:
      'Our support hubs make sure you have a supportive community by your side so that you don’t have to go through loss alone.',
    href: route('bereavement'),
    buttonText: 'Create a support hub',
    icon: HeartIcon,
  },
  {
    name: 'Dedicated Concierge',
    description:
      'Here to help you with anything you need - from support organising the funeral right through to helping with your shopping.',
    buttonText: 'Call us now',
    href: route('death-concierge'),
    icon: PhoneIcon,
  },
];

const paragraph =
  'Talking about death can be scary, but so are all the other important things in life. Let’s ask the questions. Start the conversation. Create a legacy.';
</script>

<style scoped></style>
