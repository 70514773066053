<template>
  <OLAboveFold
    :header="heroSection.header"
    header-class="w-full lg:w-7/12"
    :sub-header="heroSection.subHeader"
    :cta-text="heroSection.ctaText"
    :caption="heroSection.caption"
    :hero-link="route('lpa.typeform-onboarding')"
    :fold-bg-colour="heroSection.foldBgColour"
    :pre-call-us="ProudlyFeaturedIn"
    :image="heroSection.image"
    mobile-cta-bottom-padding="pb-14 lg:pb-0"
  />
  <BrochureSteps
    :header="stepsSection.title"
    :steps="stepsSection.steps"
    :cta-link="route('lpa.typeform-onboarding')"
    cta-text="Let’s get started"
  />
  <LpaServicesSection />
  <ExpertsCard
    class="!p-0"
    heading="Need a helping hand?"
    paragraph="You can ask our expert team who will support you every step of the way."
    :headshots="headshots"
  />
  <SamsStory :body="samSection.body" :title="samSection.title" />
  <OLTrustpilotReviews />
  <OlFaqs
    :faqs="faqs"
    heading="FAQs - Your Guide to Lasting Power of Attorney"
  />
  <SectionCta />
</template>

<script setup>
import ProudlyFeaturedIn from '@components/Brochure/ProudlyFeaturedIn.vue';
import BrochureSteps from '@components/Brochure/BrochureSteps.vue';
import LpaServicesSection from '@components/Brochure/lasting-power-of-attorney/LpaServicesSection.vue';
import SamsStory from '@components/SamsStory.vue';
import OLTrustpilotReviews from '@components/Brochure/OLTrustpilotReviews.vue';
import OlFaqs from '@components/OLFaqs.vue';
import SectionCta from '@components/Footer/SectionCta.vue';
import ExpertsCard from '@components/Probate/ExpertsCard.vue';
import OLAboveFold from '@components/OLAboveFold.vue';

const props = defineProps({
  faqs: {
    type: Array,
    required: true,
  },
});

/** AboveFold hero section data */
const heroSection = {
  header: 'Lasting power of attorney. Made easy.',
  subHeader:
    "Answer a few quick questions, and we'll guide you through completing your LPA for financial decisions, and your LPA for health and care decisions.",
  ctaText: "Let's get started",
  caption: 'Our Marketing Executive, Harriet, with her late Nanny Margaret',
  foldBgColour: 'bg-ol-accent-2-10',
  image: {
    url: new URL(
      '/public/theme/brochure/images/hero_images/lpa_hero.avif',
      import.meta.url
    ),
    alt: 'LPA Hero Image',
  },
};

const headshots = ['laura', 'eliza', 'sam', 'dylan', 'katie'];

const stepsSection = {
  title: 'Write a Lasting Power of Attorney (LPA)',
  steps: [
    {
      name: 'Create your LPA',
      description:
        'We help you create your LPA either using our online form or on the phone with one of our legal experts.',
    },
    {
      name: 'Our experts check over your documents',
      description:
        'To make sure everything is legally correct and as you want it.',
    },
    {
      name: 'Make it official',
      description:
        'We’ll send your completed forms with instructions on how to sign, witness and register your LPA with the Office of the Public Guardian.',
    },
  ],
};

const samSection = {
  title: 'Writing your Lasting Power of Attorney matters.',
  body: `In 2016, Sam’s Mum died after a car accident and his Dad was injured. It was tough enough, but having to make big decisions without a Power of Attorney added a whole other layer of stress and cost.\n\nThat’s why we started Octopus Legacy. To make it easier for you to get this stuff sorted way ahead of time, because it makes a world of difference when it matters.`,
};
</script>

<style scoped></style>
