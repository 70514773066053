<template>
  <div class="flex flex-col">
    <OLHeroSection
      class="text-balance"
      :image="sectionHero.image"
      :title="sectionHero.title"
      :sub-title="sectionHero.subTitle"
      data-testid="hero-section"
      use-new
      border-css="bg-gradient-to-br from-ol-blue-100 to-ol-pink-20"
      show-trustpilot
    >
      <template #body>
        <OLButton
          :link-to="route('death-concierge.form')"
          class="my-8 md:my-9"
          variant="special"
        >
          Get Started
          <ArrowRightIcon class="h-6" />
        </OLButton>
      </template>
    </OLHeroSection>
    <ContactUsSection :headshots="experts.headshots" class="mt-20 md:mt-28">
      <template #heading>
        <p class="text-xl sm:text-2xl xl:text-3xl 2xl:text-4xl">
          We'll support with any task - big or small
        </p>
      </template>
      <template #cta>
        <OLButton
          :link-to="
            route('leads.schedule-callback', { product: 'deathConcierge' })
          "
          variant="secondary-white"
        >
          Schedule a call
          <ArrowRightIcon class="h-6" />
        </OLButton>
      </template>
    </ContactUsSection>
    <OLIconCards
      :header="howWeCanHelp.header"
      :sub-header="howWeCanHelp.subHeader"
      :cards="howWeCanHelp.cards"
    />
    <ExpertsCard
      :heading="experts.heading"
      :paragraph="experts.paragraph"
      :headshots="experts.headshots"
    >
      <template #cta>
        <OLButton
          :link-to="route('death-concierge.form')"
          variant="secondary-white"
        >
          Get started
          <ArrowRightIcon class="h-6" />
        </OLButton>
      </template>
    </ExpertsCard>
  </div>
</template>
<script setup>
import OLIconCards from '@components/Brochure/OLIconCards.vue';
import ExpertsCard from '@components/Probate/ExpertsCard.vue';
import OLButton from '@components/Button/OLButton.vue';
import OLHeroSection from '@components/OLHeroSection.vue';
import ContactUsSection from '@components/Brochure/deathConcierge/ContactUsSection.vue';
import {
  ArrowRightIcon,
  ClipboardDocumentListIcon,
  BuildingLibraryIcon,
  CreditCardIcon,
  HeartIcon,
  TruckIcon,
  SparklesIcon,
} from '@heroicons/vue/24/outline';

const experts = {
  heading: 'Our team',
  paragraph:
    'We’re a team of people who care about making loss easier, because we’ve been through it.\nWe’re here to help you clear space for what matters.',

  headshots: ['karen', 'hayden', 'eliza'],
};
const howWeCanHelp = {
  header: 'Here to help you with anything.\nWhen you need it the most.',
  subHeader: `Loss is overwhelming enough without everything that comes with it.\nWe’re here to help take as much as possible off your plate, so you can focus on what matters.`,
  cards: [
    {
      title: 'Practical support',
      icon: ClipboardDocumentListIcon,
      paragraph:
        'We’ll guide you through the steps of what needs doing: from how to register the death, to providing information on how to notify companies and the other administrative tasks that need to be completed',
    },
    {
      title: 'Funeral support',
      icon: BuildingLibraryIcon,
      paragraph:
        'We’ll help you with anything you need in the run up to the funeral: from finding the right funeral provider, to organising the flowers.',
    },
    {
      title: 'Financial support',
      icon: CreditCardIcon,
      paragraph:
        'We’ll help you with guides on closing accounts, valuing the estate and what forms to complete. We can help connect you to the right experts and advice if you do not wish to do this yourself.',
    },
    {
      title: 'Emotional support',
      icon: HeartIcon,
      paragraph:
        'As well as providing a listening ear, our team can research different grief support options so you don’t have to. They’ll connect you with support that works around you.',
    },
    {
      title: 'Anything else',
      icon: TruckIcon,
      paragraph:
        'Get support on anything extra that is on your plate - from grocery shops, to cleaning the house, right through to day to day tasks.',
    },
    {
      title: 'Support is free',
      icon: SparklesIcon,
      iconClass: 'fill-ol-dark-ink-200 stroke-white',
      paragraph:
        'Our dedicated support comes free of charge. We’re here to help with anything you need, from research right through to organising tasks.',
    },
  ],
};
const sectionHero = {
  image: '/theme/img/background/deathConciergeHero.avif',
  title: 'Your dedicated concierge through loss',
  subTitle:
    'Here to help you with the practical, personal and emotional challenges that come after the death of someone you love.',
};

const phoneNumber = window.config.concierge_phone_number;
const phonePrint = window.config.concierge_phone_print;
</script>

<style scoped></style>
