<template>
  <section class="will-pricing-section">
    <h3 data-testid="will-pricing-header">
      What are our different types of wills?
    </h3>
    <div>
      <PricingCard
        v-for="(will, index) in willOptions"
        :key="index"
        :product-type="will.title"
        :product-description="will.description"
        :product-price="will.price"
        :product-features="will.features"
        :data-testid="`will-option-${index + 1}`"
      />
    </div>
    <OLButton
      variant="special"
      :link-to="route('will.typeform-onboarding')"
      data-testid="will-pricing-cta"
    >
      Get Started
      <Arrow />
    </OLButton>
  </section>
</template>

<script setup>
import Arrow from '@components/Icon/Arrow.vue';
import OLButton from '@components/Button/OLButton.vue';
import PricingCard from '@components/Card/PricingCard.vue';

const willOptions = [
  {
    title: 'Online',
    description: 'Create your will using our online will platform',
    price: `£${window.config.will_price}`,
    features: [
      'Reviewed by our expert team',
      'Completed online',
      'For simple estates',
    ],
  },
  {
    title: 'Phone/Home',
    description: 'Prepare your will over the phone or during a home visit',
    price: '£150',
    features: [
      'With an expert will writer',
      'Completed over the phone or in person',
      'Suitable for most estates',
    ],
  },
  {
    title: 'Will with trust',
    description: 'Manage and protects your assets for your loved ones.',
    price: '£399',
    features: [
      'With an expert will writer',
      'Completed over the phone or face to face',
      'For complex estates and trusts',
    ],
  },
];
</script>

<style lang="scss" scoped>
section.will-pricing-section {
  @apply flex
    flex-col
    items-center
    gap-8 xs:gap-10 lg:gap-14
    w-full lg:!w-9/10
    max-w-content
    bg-[unset]
    lg:mt-16
    !px-4;

  > h3 {
    @apply font-bold
    text-2xl xs:text-3xl sm:text-4xl lg:text-5xl
    lg:text-center
    w-full;
  }

  > div {
    @apply flex
    flex-col lg:flex-row
    justify-center
    gap-4
    w-full xl:w-9/10;
  }
}
</style>
