<template>
  <OLHeroSection
    title="Get in touch"
    sub-title="Need help? Got a question? We're here to help."
    border-css="!p-0 bg-[unset]"
    use-new
    skinny-padding
  >
    <template #body>
      <OLButton variant="special" :link-to="phoneLink" class="mt-10">
        Speak with our expert team
        <Arrow />
      </OLButton>
    </template>
  </OLHeroSection>
  <ContactCards />
  <OLCtaSection
    :header="sectionCta.heading"
    :content="sectionCta.subheading"
    :button-route="sectionCta.route"
    :button-text="sectionCta.buttonLabel"
  />
</template>
<script setup>
import ContactCards from '@components/Brochure/Contact/ContactCards.vue';
import OLButton from '@components/Button/OLButton.vue';
import Arrow from '@components/Icon/Arrow.vue';
import OLCtaSection from '@components/OLCtaSection.vue';
import OLHeroSection from '@components/OLHeroSection.vue';
import { useCtaDetails } from '@js/composables/useCtaDetails';

const phoneLink = `tel:${window.config.contact_phone_number}`;

const { sectionCta } = useCtaDetails();
</script>

<style scoped></style>
