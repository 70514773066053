<template>
  <div>
    <section
      class="bg-ol-accent-2-10 pt-20 lg:pt-36 pb-10 px-5 xs:px-8 sm:px-7 sm:pb-20 rounded-t-3xl"
    >
      <img class="mr-auto lg:mx-auto" :src="image" />
      <h1
        class="font-bold text-3xl md:text-4xl lg:text-5xl pb-5 md:pb-7 pt-10 lg:text-center"
      >
        {{ heading }}
      </h1>
      <p class="text-lg md:whitespace-pre-wrap lg:text-center">
        {{ paragraph }}
      </p>
    </section>
    <section
      class="relative pb-16 md:pb-2 bg-ol-accent-2-10 rounded-b-3xl px-5 xs:px-8 sm:px-7"
      :style="videoSectionMargin"
    >
      <iframe
        ref="section"
        :src="video"
        allow="autoplay"
        class="mx-auto rounded-3xl aspect-[1.78] w-full object-contain max-w-[56rem]"
        :style="videoMargin"
      />
    </section>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useElementSize, useWindowSize } from '@vueuse/core';
import { useTemplateRef } from 'vue';

const props = defineProps({
  image: {
    type: String,
    default: new URL(
      '/public/theme/brochure/images/other/flowers.png',
      import.meta.url
    ),
  },
  video: {
    type: String,
    default:
      'https://drive.google.com/file/d/1K9Dxwm-WhDa7nJYwQhpOzdUY0qFzf0pB/preview',
  },
  heading: {
    type: String,
    default: 'Our mission',
  },
  paragraph: {
    type: String,
    default: `Octopus Legacy is a place to plan for death and find support following a loss.
But it’s also a challenge: to create a legacy that connects you to people you love. In life and in death`,
  },
});

const videoSectionHeight = useTemplateRef('section');
const { height } = useElementSize(videoSectionHeight);
const { width } = useWindowSize();

const isSm = computed(() => width.value < 640);

const marginCalculation = computed(() => {
  return isSm.value ? 0 : Math.round(height.value / 2);
});

const videoMargin = computed(() => {
  return `margin-bottom:-${marginCalculation.value}`;
});
const videoSectionMargin = computed(() => {
  return `margin-bottom:${marginCalculation.value}`;
});
</script>

<style scoped></style>
