<template>
  <OLHeroSection
    class="text-balance"
    image="/theme/brochure/images/hero_images/support_hubs_hero.avif"
    title="Lasting power of attorney. Made easy."
    sub-title="Answer a few quick questions, and we’ll guide you through completing your
                LPA for financial decisions, and your LPA for health and care decisions."
    data-testid="hero-section"
    use-new
    border-css="bg-gradient-to-br from-ol-blue-100 to-ol-pink-20"
    show-trustpilot
  >
    <template #body>
      <OLButton
        :link-to="route('lpa.typeform-onboarding')"
        class="my-8"
        :variant="'special'"
      >
        Get started
        <Arrow class="my-auto" />
      </OLButton>
    </template>
  </OLHeroSection>
  <div class="p-2 sm:p-3">
    <ProudlyFeaturedIn />
    <BrochureSteps :header="stepsSection.title" :steps="stepsSection.steps" />
    <LpaServicesSection />
    <!--    <Callback />-->
    <ExpertsCard
      class="!p-0"
      heading="Need a helping hand?"
      paragraph="You can ask our expert team who will support you every step of the way."
      :headshots="headshots"
    />
    <SamsStory class="px-5 md:px-10 lg:px-20 max-w-screen-xl mx-auto">
      <template #title>
        Writing your Lasting Power of Attorney matters.
      </template>
      <template #body>
        <p class="text-base xs:text-lg sm:text-xl text-pretty mb-7">
          In 2016, Sam’s Mum died after a car accident and his Dad was injured.
          It was tough enough, but having to make big decisions without a Power
          of Attorney added a whole other layer of stress and cost.
        </p>
        <p class="text-base xs:text-lg sm:text-xl text-balance">
          That’s why we started Octopus Legacy. To make it easier for you to get
          this stuff sorted way ahead of time, because it makes a world of
          difference when it matters.
        </p>
      </template>
    </SamsStory>
    <CustomerTestimonials />
    <OlFaqs
      :faqs="faqs"
      heading="FAQs - Your Guide to Lasting Power of Attorney"
    />
    <SectionCta class="px-5 xs:px-8 md:px-7 lg:px-36" />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import OLHeroSection from '@components/OLHeroSection.vue';
import OLButton from '@components/Button/OLButton.vue';
import ProudlyFeaturedIn from '@components/Brochure/ProudlyFeaturedIn.vue';
import Arrow from '@components/Icon/Arrow.vue';
import BrochureSteps from '@components/Brochure/BrochureSteps.vue';
import LpaServicesSection from '@components/Brochure/lasting-power-of-attorney/LpaServicesSection.vue';
import SamsStory from '@components/SamsStory.vue';
import CustomerTestimonials from '@components/Brochure/OLTrustpilotReviews.vue';
import OlFaqs from '@components/OLFaqs.vue';
import SectionCta from '@components/Footer/SectionCta.vue';
import ExpertsCard from '@components/Probate/ExpertsCard.vue';

const props = defineProps({
  faqs: {
    type: Array,
    required: true,
  },
});

const headshots = ['laura', 'eliza', 'sam', 'dylan', 'katie'];

const stepsSection = {
  title: 'Write a Lasting Power of Attorney (LPA).',
  steps: [
    {
      svg: 'NumberOne',
      name: 'Create your LPA',
      description:
        'We help you create your lasting-power-of-attorney either using our online form or on the phone with one of our legal experts.',
    },
    {
      svg: 'NumberTwo',
      name: 'Our experts check over your documents',
      description:
        'To make sure everything is legally correct and as you want it.',
    },
    {
      svg: 'NumberThree',
      name: 'Make it official',
      description:
        'We’ll send your completed forms with instructions on how to sign, witness and register your LPA with the Office of the Public Guardian.',
    },
  ],
};
</script>

<style scoped></style>
