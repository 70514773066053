<template>
  <section class="you-and-your-families-section">
    <h1
      class="lg:text-center text-ol-dark-ink-200 font-bold text-3xl sm:text-4xl lg:mx-auto mb-5 2xl:text-5xl sm:whitespace-pre-wrap"
    >
      How can we help you and your families?
    </h1>
    <p
      class="lg:text-center lg:mb-14 text-ol-dark-ink-200 text-lg mb-7 xs:mb-10 sm:text-xl lg:mx-auto lg:whitespace-pre-wrap"
    >
      We partner with Funeral Directors across the UK to provide holistic
      support for families
    </p>
    <div>
      <div class="help-content-col">
        <span
          class="text-xl py-1 px-2 rounded text-ol-purple-100 bg-ol-purple-20 font-bold"
        >
          We help you
        </span>
        <div
          v-for="helpText in howCanWeHelp.you"
          class="flex-grow border-b border-ol-dark-20"
        >
          <h4 class="text-ol-dark-ink-200 text-2xl">{{ helpText.header }}</h4>
          <p class="text-ol-ashes py-4 text-lg">{{ helpText.content }}</p>
        </div>
      </div>
      <div class="help-content-col">
        <span
          class="text-xl py-1 px-2 rounded text-ol-purple-100 bg-ol-purple-20 font-bold"
        >
          We help your families
        </span>
        <div
          v-for="helpText in howCanWeHelp.families"
          class="flex-grow border-b border-ol-dark-20"
        >
          <h4 class="text-ol-dark-ink-200 text-2xl">{{ helpText.header }}</h4>
          <p class="text-ol-ashes py-4 text-lg">{{ helpText.content }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
/** The content for the "How we can help" section */
const howCanWeHelp = {
  you: [
    {
      header: 'Offer added value to your families',
      content:
        'By giving them holistic support that lasts long after the funeral has been and gone.',
    },
    {
      header: 'Digitise charitable donations',
      content: 'Saving you time and energy that you can redirect elsewhere.',
    },
    {
      header: 'Complement your existing offering',
      content:
        'Online communities can open up new kinds of support in an increasingly digital age.',
    },
    {
      header: 'Gain free brand exposure',
      content:
        'Each Support Hub allows you to reach an average of 332 visitors, at zero cost to you',
    },
    {
      header: 'By doing the work for you.',
      content:
        'We integrate Support Hubs to your website for FREE, so you don’t have to lift a finger.',
    },
  ],
  families: [
    {
      header: 'Celebrate their loved ones.',
      content:
        'By creating a safe space to share stories, photos, and raise money for charity.',
    },
    {
      header: 'Save time and stress.',
      content:
        'We streamline communication and plan out support to ease the burden on your families.',
    },
    {
      header: 'Feel supported.',
      content:
        'Families can choose who to invite, creating close private and supportive communities.',
    },
    {
      header: 'Include those unable to attend the funeral',
      content:
        'Support Hubs allow people to grieve from afar. Before, during and after the funeral.',
    },
    {
      header: 'Grieve in their own time.',
      content:
        'Grief isn’t linear. Families can (and do) come back to the Support Hub at any time.',
    },
  ],
};
</script>

<style lang="scss" scoped>
section.you-and-your-families-section {
  @apply bg-[unset]
  flex
  flex-col
  items-center
  mx-4 md:mx-12
  mb-12 lg:mb-0;

  > div {
    @apply grid
    lg:grid-cols-2
    gap-20 xl:gap-40
    max-w-[58rem];

    > div.help-content-col {
      @apply flex
      flex-col
      items-start
      gap-8 xs:gap-12 sm:gap-16
      h-full
      w-full;

      > div {
        @apply w-full;
      }
    }
  }
}
</style>
