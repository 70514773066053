<template>
  <section class="our-services">
    <div class="flex-col">
      <h3 class="our-services__header" data-testid="our-services-header">
        {{ header }}
      </h3>
      <h4 class="our-services__subheader" data-testid="our-services-subheader">
        {{ subHeader }}
      </h4>
    </div>
    <div class="our-services__container" data-testid="our-services-container">
      <ServiceCard
        v-for="service in services"
        :key="service.header"
        :header="service.header"
        :sub-header="service.subHeader"
        :bullet-points="service.bulletPoints"
        :prices="service.prices"
        :fine-print="service.finePrint"
        :typeform="typeform"
        :utm-params="utmParams"
        :cta-text="service.ctaText"
        :link-to-prices="service.linkToPrices"
      />
    </div>
  </section>
</template>

<script>
import ServiceCard from '@components/Brochure/ServiceCard.vue';

export default {
  components: {
    ServiceCard,
  },
  props: {
    header: {
      type: String,
      required: true,
    },
    subHeader: {
      type: String,
      required: true,
    },
    services: {
      type: Array,
      required: true,
    },
    typeform: {
      type: String,
      required: true,
    },
    utmParams: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.our-services {
  @apply flex
    flex-col
    items-center
    rounded-3xl
    px-4 xs:px-8
    py-20 xs:py-24 md:py-36;
}

.our-services__container {
  @apply flex
    flex-col xl:flex-row
    gap-4 lg:gap-8
    w-full max-w-[74rem];
}

.our-services__header {
  @apply text-ol-cream
    text-3xl sm:text-4xl
    lg:text-center
    mb-6 md:mb-8;
}

.our-services__subheader {
  @apply text-ol-cream
    text-lg sm:text-xl
    lg:text-center
    mb-10 xs:mb-12 sm:mb-16 md:mb-20
    max-w-[50rem];
}
</style>
