<template>
  <OLHeroSection
    image="/theme/brochure/images/hero_images/support_hubs_hero.avif"
    title="We can't make loss easy. But we can help."
    header-class="max-w-[48rem] 2xl:max-w-[54rem]"
    sub-title="Our support hubs make sure you have a supportive community by your side so that you don’t have to go through loss alone."
    sub-header-class="max-w-[48rem] 2xl:max-w-[54rem]"
    data-testid="hero-section"
    border-css="bg-gradient-to-br from-ol-blue-100 to-ol-pink-20"
    show-trustpilot
  >
    <template #body>
      <div class="flex flex-col md:flex-row gap-3 md:gap-5 my-8">
        <OLButton :link-to="route('support-hubs.create')" variant="special">
          Create a support hub
          <ArrowRightIcon class="h-6" />
        </OLButton>
        <OLButton
          :link-to="route('support-hubs.join-page')"
          variant="secondary"
        >
          Join a support hub
          <ArrowRightIcon class="h-6" />
        </OLButton>
      </div>
    </template>
  </OLHeroSection>
  <ProudlyFeaturedIn class="mt-20" />
  <OLIconCards
    :header="cards.header"
    :sub-header="cards.subHeader"
    :cards="cards.cards"
  />
  <BrochureSteps
    :header="stepsSection.title"
    :steps="stepsSection.steps"
    dark-mode
  />
  <SamsStory :title="samSection.title" :body="samSection.body" />
  <OLTrustpilotReviews header="Don’t listen to us. Listen to our customers." />
  <SectionCta class="mt-2 sm:mt-3 lg:mt-28 xl:mt-36" />
</template>

<script setup>
import BrochureSteps from '@components/Brochure/BrochureSteps.vue';
import OLButton from '@components/Button/OLButton.vue';
import OLHeroSection from '@components/OLHeroSection.vue';
import OLIconCards from '@components/Brochure/OLIconCards.vue';
import ProudlyFeaturedIn from '@components/Brochure/ProudlyFeaturedIn.vue';
import SamsStory from '@components/SamsStory.vue';

import {
  ArrowRightIcon,
  PhotoIcon,
  ComputerDesktopIcon,
  BanknotesIcon,
  ListBulletIcon,
  TruckIcon,
  ClockIcon,
} from '@heroicons/vue/24/outline';
import OLTrustpilotReviews from '@components/Brochure/OLTrustpilotReviews.vue';
import SectionCta from '@components/Footer/SectionCta.vue';

const cards = {
  header: 'Losing someone is overwhelming',
  subHeader:
    'We’re here to help you manage the chaos.  Our support hub will support you in the run up to a funeral and long after. ',
  cards: [
    {
      title: 'Share memories',
      icon: PhotoIcon,
      paragraph: 'Share photos and messages to celebrate your loved one.',
    },
    {
      title: 'Share funeral information',
      icon: ComputerDesktopIcon,
      paragraph: 'All in one place so you can focus on what’s important.',
    },
    {
      title: 'Create to-do lists',
      icon: ListBulletIcon,
      paragraph: "Delegate tasks so you can focus on what's important.",
    },
    {
      title: 'Space out deliveries',
      icon: TruckIcon,
      paragraph: 'To avoid being swamped by flower and food deliveries.',
    },
    {
      title: 'Raise money for charity',
      icon: BanknotesIcon,
      paragraph: 'In memory of your loved one & the causes they cared for.',
    },
    {
      title: 'Carve out visiting times',
      icon: ClockIcon,
      paragraph: 'As well as time just for you and your closest family.',
    },
  ],
};

const stepsSection = {
  title: 'Support hubs:\nHow they work.',
  steps: [
    {
      name: 'Create a support hub',
      description:
        'In memory of the person you’ve lost. This takes less than 2 minutes.',
    },
    {
      name: 'Build a supportive community',
      description:
        'Invite friends and family to join your support hub by sharing a single link.',
    },
    {
      name: 'Get support',
      description:
        'Make the most of our tools to support you through all of the challenges that come with loss.',
    },
  ],
};

const samSection = {
  title: 'Loss made easier. By people who’ve been through it.',
  body: 'Sam founded Octopus Legacy after his mum died in 2016. We’ve experienced first-hand how chaotic and overwhelming loss can be.\n\nOur mission is to make your experience of loss easier. That is why we built the support hubs, they’re the tool we wished we’d had.',
};
</script>

<style scoped></style>
