<template>
  <div
    class="px-5 xs:px-8 md:px-7 lg:px-40 xl:px-52 2xl:px-64 py-20 lg:py-28 xl:py-36 mx-auto bg-ol-secondary-20 rounded-3xl w-full"
  >
    <div class="max-w-content mx-auto">
      <HeadingWithDescription
        class="px-5 md:px-32 pb-10 lg:pb-14 bg-ol-secondary-20"
        :heading="fromOurTeam.heading"
        :paragraph="fromOurTeam.paragraph"
      />
      <TwoColumnGrid>
        <template #leftColumn>
          <img class="rounded-ol-card" :src="fromOurTeam.image1" />

          <Testimonial
            class="flex-grow"
            :testimonial="fromOurTeam.testimonials[0]"
          />

          <img class="rounded-ol-card" :src="fromOurTeam.image2" />
        </template>
        <template #rightColumn>
          <Testimonial
            class="flex-grow"
            :testimonial="fromOurTeam.testimonials[1]"
          />

          <video
            id="vid"
            autoplay
            playsinline
            loop
            muted
            class="rounded-ol-card"
            :src="fromOurTeam.video"
          />
          <Testimonial
            class="flex-grow"
            :testimonial="fromOurTeam.testimonials[2]"
          />
        </template>
      </TwoColumnGrid>
    </div>
  </div>
</template>
<script>
import HeadingWithDescription from '@components/Brochure/HeadingWithDescription.vue';
import Testimonial from '@js/components/Brochure/careers/Testimonial.vue';
import TwoColumnGrid from '@components/Layout/TwoColumnGrid.vue';

export default {
  components: { TwoColumnGrid, Testimonial, HeadingWithDescription },
  props: {
    fromOurTeam: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style></style>
