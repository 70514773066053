<template>
  <section class="contact-cards">
    <h3 class="contact-cards__header" data-testid="contact-cards-header">
      Can’t find what you’re looking for?
    </h3>
    <div class="contact-cards__container">
      <OLIconCard
        :icon="ChatBubbleLeftRightIcon"
        title="For customer help & support"
        data-testid="contact-cards-customer-support"
        class="contact-cards__card"
      >
        <div class="contact-cards__container__content">
          <p>
            Our friendly team are on-hand to help you with any questions,
            challenges or concerns. We know this stuff can be confusing. We’re
            here to make it simple.
          </p>
          <div class="flex flex-col gap-y-2">
            <a href="javascript:Intercom('show');" class="contact-cards__link">
              Chat to us
            </a>
            <a :href="`mailto:${email}`" class="contact-cards__link">
              {{ email }}
            </a>
            <a :href="`tel:${phoneNumber}`" class="contact-cards__link">
              {{ phonePrint }}
            </a>
          </div>
        </div>
      </OLIconCard>
      <OLIconCard
        :icon="BriefcaseIcon"
        title="Work with us"
        data-testid="contact-cards-work-with-us"
        class="contact-cards__card"
      >
        <div class="contact-cards__container__content">
          <p>
            Are you a Charity, Funeral Director or Employer that’s interested in
            partnering with us? Get in touch with our team to find out more.
          </p>
          <a :href="`mailto:${salesEmail}`" class="contact-cards__link">
            {{ salesEmail }}
          </a>
          <div>
            <h4 class="text-lg font-bold text-ol-dark-ink-200">
              Looking to join our team?
            </h4>
            <p>Check out how you can help be a part of our mission</p>
          </div>

          <a :href="route('careers')" class="contact-cards__link">
            Jobs page
            <ArrowRightIcon class="h-3 mt-1" />
          </a>
        </div>
      </OLIconCard>
      <OLIconCard
        :icon="ScaleIcon"
        title="Octopus Legal Services"
        data-testid="contact-cards-octopus-legal"
        class="contact-cards__card"
      >
        <div class="contact-cards__container__content">
          <p>
            For questions relating to probate or our probate services, please
            contact Octopus Legal Services. For anything else, you can contact
            our customer help and support.
          </p>

          <div class="flex flex-col gap-y-2">
            <a :href="`mailto:${probateEmail}`" class="contact-cards__link">
              {{ probateEmail }}
            </a>
            <a :href="`tel:${legalPhoneNumber}`" class="contact-cards__link">
              {{ legalPhonePrint }}
            </a>
          </div>
        </div>
      </OLIconCard>
    </div>
  </section>
</template>

<script setup>
import OLIconCard from '@components/Brochure/OLIconCard.vue';
import {
  ArrowRightIcon,
  BriefcaseIcon,
  ChatBubbleLeftRightIcon,
  ScaleIcon,
} from '@heroicons/vue/24/outline';

const phoneNumber = window.config.contact_phone_number;
const phonePrint = window.config.contact_phone_print;
const email = window.config.contact_email;
const salesEmail = window.config.sales_email;
const probateEmail = window.config.legal_email;
const legalPhoneNumber = window.config.legal_phone_number;
const legalPhonePrint = window.config.legal_phone_print;
</script>

<style lang="scss" scoped>
.contact-cards {
  @apply flex
  flex-col lg:flex-row
  items-center lg:items-start
  self-stretch justify-between
  gap-y-8
  lg:gap-x-30 xl:gap-x-40 2xl:gap-x-56
  bg-ol-cream
  py-20 lg:py-28 xl:py-36
  md:px-7 lg:px-[4.375rem] xl:px-32 2xl:px-0
  max-w-content mx-auto;
}

.contact-cards__header {
  @apply w-full lg:w-88
    px-5 lg:px-0
    text-2xl md:text-3xl xl:text-4xl;
}

.contact-cards__subheader {
  @apply text-center text-xl;
}

.contact-cards__container {
  @apply grid
  lg:grid-cols-1
  gap-7 lg:gap-[2.625rem] flex-1;
}

.contact-cards__card {
  @apply w-full
  py-8
  px-5
  xs:p-8 sm:p-10;
}

.contact-cards__container__content {
  @apply flex
  flex-col
  gap-6
  text-base lg:text-lg
  text-ol-dark-100;
}

.contact-cards__link {
  @apply flex
  items-center
  gap-x-1
  w-fit
  text-base xs:text-xl
  text-ol-purple-100
  hover:text-ol-dark-ink-200
  transition-colors;
}
</style>
