<template>
  <section class="faq-section">
    <div class="faq-container">
      <slot name="heading">
        <h2 class="faq-container__heading">
          {{ heading }}
        </h2>
      </slot>
      <accordion-panel v-for="(faq, index) in filteredFaqs" :key="index">
        <template #header>
          {{ faq.question }}
        </template>
        <template #body>
          <p v-html="faq.answer"></p>
        </template>
      </accordion-panel>

      <OLButton
        v-show="faqs.length > 4"
        data-testid="showMoreFaqs"
        class="mt-10 sm:mt-[2.75rem] lg:mt-16 self-center w-fit"
        variant="secondary"
        @click="toggleFaqLimit"
      >
        {{ limit === MINIMUM_NUMBER_OF_FAQS ? 'Show more' : 'Show less' }}
      </OLButton>
    </div>
  </section>
</template>

<script setup>
import AccordionPanel from '@components/AccordionPanel.vue';
import { computed, ref } from 'vue';
import OLButton from '@components/Button/OLButton.vue';

const props = defineProps({
  heading: {
    type: String,
    default: '',
  },
  faqs: {
    type: Array,
    required: true,
    validator(value) {
      return value.length >= 1;
    },
  },
});

const MINIMUM_NUMBER_OF_FAQS = props.faqs.length < 4 ? props.faqs.length : 4;

const limit = ref(MINIMUM_NUMBER_OF_FAQS);

const toggleFaqLimit = () => {
  limit.value =
    limit.value === MINIMUM_NUMBER_OF_FAQS
      ? props.faqs.length
      : MINIMUM_NUMBER_OF_FAQS;
};

const filteredFaqs = computed(() => props.faqs.slice(0, limit.value));
</script>

<style lang="scss" scoped>
.faq-section {
  @apply bg-ol-cream
  flex flex-col justify-center
  py-20 md:py-28 lg:py-36
  px-5 xs:px-8 sm:px-7 md:px-[4.375rem] !m-0;
}

.faq-container {
  @apply flex flex-col self-center max-w-[68.875rem];

  &__heading {
    @apply text-2xl xs:text-3xl sm:text-4xl md:text-3xl lg:text-4xl xl:text-5xl
    mb-9 xs:mb-5 xl:mb-12;
  }
}
</style>
