<template>
  <div class="md:bg-ol-dark-ink-200 rounded-t-3xl md:h-96 md:mt-28 lg:mt-56" />
  <figure
    class="relative xl:px-0 mx-auto md:-mt-135 lg:-mt-145 max-w-content md:max-w-screen-sm lg:max-w-[900px] xl:max-w-screen-lg"
  >
    <img
      :alt="imageAltText"
      :src="image"
      class="xs:aspect-auto aspect-square object-cover rounded-3xl md:h-72 lg:h-96 w-full mx-auto"
    />
    <figcaption
      class="text-white drop-shadow-2xl text-xs absolute right-[7%] bottom-[7%] w-1/2 md:w-1/4 lg:w-[unset] p-1 rounded"
    >
      {{ figureCaption }}
    </figcaption>
    <div
      class="flex flex-col gap-2 lg:gap-5 absolute left-[7%] top-1/3 md:top-1/4 align-middle text-white whitespace-pre-wrap text-xl md:text-2xl lg:text-3xl xl:text-4xl sm:w-1/3"
    >
      <h2 class="drop-shadow-2xl">
        {{ imageText1 }}
      </h2>
      <h2 class="drop-shadow-2xl">
        {{ imageText2 }}
      </h2>
    </div>
  </figure>
</template>

<script setup>
import { computed, useTemplateRef } from 'vue';
import { useElementSize, useWindowSize } from '@vueuse/core';

const props = defineProps({
  image: {
    type: URL,
    required: true,
  },
  imageAltText: {
    type: String,
    default: '',
  },
  figureCaption: {
    type: String,
    default: '',
  },
  imageText1: {
    type: String,
    default: '',
  },
  imageText2: {
    type: String,
    default: '',
  },
});

const videoSectionHeight = useTemplateRef('section');
const { height } = useElementSize(videoSectionHeight);
const { width } = useWindowSize();

const isSm = computed(() => width.value < 640);

const marginCalculation = computed(() => {
  return isSm.value ? 0 : Math.round(height.value / 2);
});

const videoMargin = computed(() => {
  return `margin-bottom:-${marginCalculation.value}`;
});
const videoSectionMargin = computed(() => {
  return `margin-bottom:${marginCalculation.value}`;
});
</script>

<style lang="scss" scoped></style>
