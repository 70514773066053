<template>
  <div class="ol-featured-in" :class="{ 'pt-20 pb-5': useOld }">
    <p>Proudly featured in</p>
    <div>
      <a
        v-for="(source, index) in featuredSources"
        :key="index"
        :href="source.link"
        target="_blank"
      >
        <img :src="source.image" :alt="source.alt" />
      </a>
    </div>
  </div>
</template>

<script setup>
const useOld = !window.config.new_brochure;

const featuredSources = [
  {
    link: 'https://www.telegraph.co.uk/family/life/shock-mums-death-made-rethink-everything/',
    image: new URL(
      '/public/theme/img/press-logos/telegraph.png',
      import.meta.url
    ),
    alt: 'Telegraph logo',
  },
  {
    link: 'https://www.ftadviser.com/companies/2022/12/12/octopus-buys-tech-firm-to-help-people-prepare-for-death/',
    image: new URL(
      '/public/theme/img/press-logos/financial-times.png',
      import.meta.url
    ),
    alt: 'Financial Times logo',
  },
  {
    link: 'https://www.manchestereveningnews.co.uk/business/business-news/death-tech-start-up-guardian-16136099',
    image: new URL(
      '/public/theme/img/press-logos/manchester-evening-news.png',
      import.meta.url
    ),
    alt: 'Manchester Evening News logo',
  },
  {
    link: 'https://www.socialenterprisebsr.net/2021/08/the-uk-social-enterprise-awards-2021-shortlist-announced/',
    image: new URL(
      '/public/theme/img/press-logos/social-enterprise-uk.png',
      import.meta.url
    ),
    alt: 'Social Enterprise UK logo',
  },
];
</script>

<style lang="scss" scoped>
.ol-featured-in {
  @apply flex
    flex-col
    items-center
    gap-5 sm:gap-3;

  > p {
    @apply text-ol-dark-ink-200
      text-ol-fineprint sm:text-base lg:text-xl;
  }

  > div {
    @apply grid
      grid-cols-2 md:grid-cols-4
      place-items-center
      gap-4 lg:gap-8 xl:gap-12 2xl:gap-16
      w-full lg:w-9/10 xl:w-5/6 2xl:w-8/10
      max-w-content
      lg:px-6;

    > a {
      @apply w-3/4 no-underline;
    }
  }
}
</style>
