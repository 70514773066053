<template>
  <OLAboveFold
    :header="heroSection.header"
    header-class="w-full lg:w-1/2"
    :sub-header="heroSection.subHeader"
    sub-header-class="w-full lg:w-8/10"
    :cta-text="heroSection.ctaText"
    :caption="heroSection.caption"
    :hero-link="route('bow.onboarding2.welcome')"
    :fold-bg-colour="heroSection.foldBgColour"
    :pre-call-us="ProudlyFeaturedIn"
    :image="heroSection.image"
    mobile-cta-bottom-padding="pb-14 lg:pb-0"
  />
  <BrochureSteps
    :header="stepsSection.title"
    :steps="stepsSection.steps"
    cta-text="Take the quiz"
    :cta-link="route('bow.onboarding2.welcome')"
  />
  <ExpertsCard
    class="!p-0"
    heading="Need a helping hand?"
    paragraph="You can ask our expert team who will support you every step of the way."
    :headshots="['laura', 'eliza', 'sam', 'dylan', 'katie']"
  />
  <SamsStory :title="samsStory.title" :body="samsStory.body" />
  <OLTrustpilotReviews />
  <SectionCta class="mt-2 sm:mt-3 lg:mt-28 xl:mt-36" />
</template>

<script setup>
import ProudlyFeaturedIn from '@components/Brochure/ProudlyFeaturedIn.vue';
import OLAboveFold from '@components/OLAboveFold.vue';
import BrochureSteps from '@components/Brochure/BrochureSteps.vue';
import SectionCta from '@components/Footer/SectionCta.vue';
import ExpertsCard from '@components/Probate/ExpertsCard.vue';
import SamsStory from '@components/SamsStory.vue';
import OLTrustpilotReviews from '@components/Brochure/OLTrustpilotReviews.vue';

/** AboveFold hero section data */
const heroSection = {
  header: 'Estate Planning that won’t be the death of you.',
  subHeader:
    'Giving the gift of a back up plan to the people you love just got a whole lot easier.',
  ctaText: 'Take the quiz',
  caption: 'Our Senior People & Talent Manager, Charlotte’s late grandparents',
  foldBgColour: 'bg-ol-secondary-50',
  image: {
    url: new URL(
      '/public/theme/brochure/images/hero_images/estate_planning_hero.avif',
      import.meta.url
    ),
    alt: 'Estate Planning Hero Image',
  },
};

/** 3-steps section data */
const stepsSection = {
  title: 'The UK’s first one-stop-shop for Estate Planning',
  steps: [
    {
      name: 'Find out how prepared you are',
      description:
        'Take the quiz and see what legal, financial, and emotional plans you already have in place, and what still needs sorting.',
    },
    {
      name: 'Get free tips on how to improve',
      description:
        'We give you free tailored recommendations based on your situation.',
    },
    {
      name: 'Manage everything in one place',
      description:
        'Save time and money by sorting all your key documents and plans in one place. Choose the plans you need and come back and edit them at any time.',
    },
  ],
};

/** Text for Sams Story section */
const samsStory = {
  title: 'Your plan isn’t for you. It’s a gift for the people you love.',
  body: 'We know because we’ve been there. Sam founded Octopus Legacy after his Mum died in 2016. It was tough enough, but without a plan in place it was overwhelming – endless forms and calls and worrying if they’d got the funeral right.\n\nCreating a plan today makes life easier for people you love in the future.',
};
</script>

<style scoped></style>
