<template>
  <div class="row">
    <div class="column">
      <slot name="leftColumn" />
    </div>
    <div class="column">
      <slot name="rightColumn" />
    </div>
  </div>
</template>

<style scoped>
.row {
  @apply flex flex-wrap max-w-content gap-10;
}

.column {
  @apply w-full md:flex-col md:flex lg:justify-between lg:w-1/2 lg:flex-1 gap-y-5 sm:gap-y-6 lg:gap-y-10;
}
</style>
