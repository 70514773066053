<template>
  <section class="better-value">
    <h3>Better value for you</h3>
    <div class="better-value__cards">
      <div>
        <span>WITH <img :src="octopusLogo" alt="" /></span>
        <b v-if="freeWill">IT'S FREE</b>
        <b v-else-if="partner">
          {{ partner.campaignPrice }} Thanks to {{ partner.name }}
        </b>
        <b v-else>Only £{{ willPrice }}</b>
      </div>
      <div>
        <p>OTHER UK PROVIDERS</p>
        <b>Between<br />£150-£300</b>
      </div>
    </div>
    <template v-if="showChangeWill">
      <h3 class="mt-20 lg:mt-28 xl:mt-36">
        What happens if I need to change my will?
      </h3>
      <p class="text-center text-xl">
        Life changes. Wills should too. Unlike other will services we allow you
        to edit and change your will at any time. And it won’t cost the earth.
      </p>
      <div class="better-value__cards">
        <div>
          <p>You get the first year for FREE</p>
        </div>
        <div>
          <p>It’s only £10 per year after</p>
        </div>
      </div>
      <p class="mt-6 text-center font-medium text-black text-xl xl:text-2xl">
        * You can opt-out anytime
      </p>
    </template>
    <p v-else>*Prices may differ if we acquire your will from a will bank.</p>
  </section>
</template>

<script setup>
const props = defineProps({
  partner: {
    type: Object,
    default: null,
  },
  freeWill: {
    type: Boolean,
    default: false,
  },
  showChangeWill: {
    type: Boolean,
    default: false,
  },
});

const octopusLogo = new URL(
  '/public/theme/ol/logo-secondary-color.svg',
  import.meta.url
);

const willPrice = window.config.will_price;
</script>

<style lang="scss" scoped>
section.better-value {
  @apply bg-ol-secondary-20
    flex
    flex-col
    justify-center
    items-center
    w-full
    py-20 md:py-28 lg:py-36
    px-3 xs:px-7
    rounded-3xl
    mt-2 sm:mt-3
    text-ol-dark-ink-200;

  > h3 {
    @apply text-center font-bold
      text-3xl sm:text-4xl xl:text-5xl
      mb-5 xl:mb-7;
  }

  > p {
    @apply text-center;
  }

  > p:first-of-type {
    @apply text-base xs:text-xl xl:text-2xl;
  }

  > p:last-of-type {
    @apply text-2xs xs:text-xs md:text-sm;
  }

  > div.better-value__cards {
    @apply flex
      flex-col lg:flex-row
      gap-6 lg:gap-10 xl:gap-12
      w-full
      max-w-content
      my-10 lg:mt-16;

    > div {
      @apply flex
        flex-col
        justify-center
        items-center
        gap-11 sm:gap-14 md:gap-20
        w-full
        bg-white
        shadow-2xl
        rounded-2xl
        px-5
        py-16;

      > p,
      > b {
        @apply text-center;
      }

      > p {
        @apply font-bold;
      }

      > b {
        @apply text-3xl lg:text-4xl xl:text-5xl;
      }

      > span,
      > p {
        @apply text-2xl;
      }

      &:first-of-type {
        @apply text-ol-secondary-200;
        > span {
          @apply flex
            gap-2;

          > img {
            @apply h-8;
          }
        }
      }

      &:last-of-type {
        @apply text-ol-dark-ink-200;
      }
    }
  }
}
</style>
