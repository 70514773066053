<template>
  <section class="products-section" :class="backgroundColor">
    <div class="products-section-container">
      <slot name="heading">
        <h2 class="products-section-container__heading">
          Call us now on
          <a
            :href="`tel:${phone_number}`"
            class="font-bold text-nowrap inline-block w-full sm:inline"
          >
            {{ phone_print }}
          </a>
          to learn more about
        </h2>
      </slot>
      <ul class="products-section-container__list">
        <li
          v-for="product in products"
          :key="product.title"
          class="flex justify-center self-stretch items-center sm:w-1/2 lg:w-fit"
        >
          <BrochureProduct :product="product" />
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Container from '../Core/Container.vue';

import BrochureProduct from './BrochureProduct.vue';

export default {
  components: {
    BrochureProduct,
    Container,
  },
  props: {
    products: { type: Array, required: true },
    backgroundColor: {
      type: String,
      default: 'bg-ol-accent-2-10',
    },
  },
  data() {
    return {
      phone_number: window.config.contact_phone_number,
      phone_print: window.config.contact_phone_print,
    };
  },
};
</script>

<style lang="scss" scoped>
.products-section {
  @apply rounded-3xl
  py-20 lg:py-36
  px-5 xs:px-10 md:px-20 lg:px-36
  flex flex-col items-center w-full
  my-2 md:my-3;
}

.products-section-container {
  @apply flex flex-col gap-y-16 max-w-[1001px];

  &__heading {
    @apply text-2xl lg:text-3xl text-center font-normal;
  }

  &__list {
    @apply flex  self-stretch
    flex-col sm:flex-row
    justify-between
    items-center md:items-start
    flex-wrap lg:flex-nowrap
    gap-y-14 lg:gap-x-14;
  }
}
</style>
