<template>
  <SlidingText
    :sliding-paragraphs="slidingParagraphs"
    :background-image="slidingTextImage"
  />
  <AboutUsVideoSection />
  <SamsStory
    image-bottom-on-mobile
    :title="ourJob.heading"
    :body="ourJob.paragraph"
    :image="ourJob.image"
    :primary-cta="ourJob.primaryCta"
    :secondary-cta="ourJob.secondaryCta"
  />
  <MeetTheTeamSection />
  <OLTrustpilotReviews />
  <SectionCta class="lg:mt-28 xl:mt-36 my-2" />
  <NewsLetterSignup />
</template>

<script setup>
import SlidingText from '@components/Brochure/SlidingText.vue';
import OLTrustpilotReviews from '@components/Brochure/OLTrustpilotReviews.vue';
import SamsStory from '@components/SamsStory.vue';
import AboutUsVideoSection from '@components/Brochure/aboutUS/AboutUsVideoSection.vue';
import SectionCta from '@components/Footer/SectionCta.vue';
import NewsLetterSignup from '@components/NewsLetterSignup.vue';
import MeetTheTeamSection from '@components/Brochure/aboutUS/MeetTheTeamSection.vue';

const ourJob = {
  heading: 'Our job is to bring death to life.',
  paragraph: `Born after Sam’s mum died in a car accident, we know the difference a good plan makes, and what it’s like when there isn’t one.\n
We see a world where you work out your personal meaning of legacy by talking about death with people you love. We’re here to make that happen.`,
  image: new URL('/public/theme/ol/Sam-&-Mum.jpeg', import.meta.url),
  primaryCta: { text: 'Plan for death', linkTo: route('estate-planning') },
  secondaryCta: {
    text: 'Get support after loss',
    linkTo: route('bereavement'),
  },
};

const slidingTextImage = 'theme/brochure/backgrounds/aboutUsBackground.avif';
const slidingParagraphs = [
  { text: 'Death can feel like the full stop at \nthe end of our lives.' },
  {
    text: 'But what if we treated it more like\nan invitation?',
  },
  {
    text: 'For more words, more\nconversations',
  },
];
</script>

<style scoped></style>
