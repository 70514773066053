<template>
  <section class="how-it-works">
    <h5 class="how-it-works__header" data-testid="fwig-how-it-works-header">
      How it works
    </h5>
    <p
      class="how-it-works__description"
      data-testid="fwig-how-it-works-description"
    >
      We're bringing planning back to life with this easy to use and fun to
      navigate box.
    </p>
    <div
      class="how-it-works__container"
      data-testid="fwig-how-it-works-container"
    >
      <div
        v-for="(step, index) in stepByStep"
        :key="index"
        class="how-it-works__card"
      >
        <!-- <span class="w-40 h-40 border-2 border-ol-dark-ink-200" /> -->
        <b>{{ index + 1 }}. {{ step.step }}</b>
        <p>
          {{ step.description }}
        </p>
      </div>
    </div>
  </section>
</template>

<script setup>
const stepByStep = [
  {
    step: 'Place your order',
    description:
      'The box can be delivered directly to you, or it can be sent as a gift to someone you love.',
  },
  {
    step: 'Fill the box with your plans ',
    description:
      'Store your will, insurance documents and account information, all in one safe space. This small but mighty act makes life easier for the people you leave behind - creating space for them to focus on what matters.',
  },
  {
    step: 'Share more than just money',
    description:
      'This is your invitation to share something much more valuable. Share photos, memories, advice, wishes, letters and much more.',
  },
];
</script>

<style lang="scss" scoped>
.how-it-works {
  @apply flex
    flex-col
    items-center
    rounded-2xl
    bg-ol-accent-2-20
    px-5 lg:px-10
    py-20 md:py-24 lg:py-36;
}

.how-it-works__header {
  @apply text-4xl
    text-ol-dark-ink-200
    font-bold
    mb-5;
}

.how-it-works__description {
  @apply sm:text-lg md:text-xl
    text-center lg:text-left
    mb-12 lg:mb-20;
}

.how-it-works__container {
  @apply flex
    flex-col md:flex-row
    gap-y-16 md:gap-y-0
    md:gap-x-16
    w-full
    max-w-content
    lg:px-12;
}

.how-it-works__card {
  @apply flex
    flex-col
    items-center
    gap-y-4
    w-full;

  > b {
    @apply md:self-start;
  }
}
</style>
