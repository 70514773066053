<template>
  <section class="bg-ol-cream">
    <div class="whats-in-the-box">
      <div class="lg:col-span-2">
        <h2 class="whats-in-the-box__header">What's in the box?</h2>
        <p class="mt-4 text-ol-ashes lg:text-xl">
          When most people think about what they'll leave behind they think of
          the practical stuff - money, a plan for the house, life insurance. But
          why stop there? This box is your invitation to give something much
          more valuable to the people you love.
        </p>
      </div>
      <dl class="whats-in-the-box__container">
        <div
          v-for="feature in features"
          :key="feature.name"
          class="border-t border-ol-dark-20 pt-4"
        >
          <dt class="font-bold text-ol-dark-ink-200 text-lg">
            {{ feature.name }}
          </dt>
          <dd class="mt-3 text-ol-ashes">
            {{ feature.description }}
          </dd>
        </div>
      </dl>
      <div class="product-images">
        <div v-for="productImage in productImages" :key="productImage.title">
          <img
            :src="productImage.src"
            :alt="productImage.title"
            class="rounded-lg bg-gray-100"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
const features = [
  {
    name: 'Share your plans for the future',
    description:
      'There’s space to include your will, accounts, and insurance in one easy-to-find place.',
  },
  {
    name: 'Get creative',
    description: 'Use this box as a springboard to share what makes you you.',
  },
  {
    name: 'Connect with conversation cards',
    description:
      'Use our card game to get to know the people you love even better and spark conversations worth having.',
  },
  {
    name: 'Share the things that really matter',
    description:
      'Your hard earned advice and wisdom, your much-loved recipes, your funeral wishes, letters, memories and photos.',
  },
  {
    name: 'Share voice messages',
    description:
      'Use this dictaphone to leave messages, share stories, and voice the things you don’t say enough.',
  },
  {
    name: 'Share the soundtrack of your life',
    description:
      'Note down the songs that have accompanied you through the highs and lows of life.',
  },
];

const productImages = [
  {
    title: 'Legacy Box Unboxed',
    src: new URL(
      '/public/theme/img/fwig/product-unboxed.avif',
      import.meta.url
    ),
  },
  {
    title: 'Legacy Box',
    src: new URL('/public/theme/img/fwig/product-image.avif', import.meta.url),
  },
  {
    title: 'Legacy Card',
    src: new URL('/public/theme/img/fwig/product-card.avif', import.meta.url),
  },
  {
    title: 'Legacy Photos',
    src: new URL('/public/theme/img/fwig/product-photos.avif', import.meta.url),
  },
];
</script>

<style lang="scss" scoped>
.whats-in-the-box {
  @apply grid
  grid-cols-1 lg:grid-cols-2
  items-center
  gap-16
  px-4
  py-24 sm:py-32
  max-w-content
  mx-auto;
}

.whats-in-the-box__header {
  @apply text-3xl sm:text-4xl
  font-bold
  tracking-tight
  text-ol-dark-ink-200;
}

.whats-in-the-box__container {
  @apply grid
  grid-cols-1 sm:grid-cols-2
  gap-x-6 lg:gap-x-8
  gap-y-10 sm:gap-y-16
  w-full;
}

.product-images {
  @apply grid
  grid-cols-2
  grid-rows-2
  gap-4 sm:gap-6 lg:gap-8;

  > div {
    @apply relative
    w-full h-full
    rounded-2xl
    bg-clip-content
    overflow-hidden
    aspect-1;

    > img {
      @apply absolute
      top-1/2
      left-1/2
      min-h-full
      min-w-[101%]
      object-cover;
      transform: translate(-50.05%, -50%);
    }
  }
}
</style>
