<template>
  <div
    :style="background"
    class="bg-no-repeat bg-cover mb-2 sm:mb-3 rounded-3xl mt-2 sm:mt-3"
  >
    <VerticalScrollAnimation class="bg-fixed" scale="scale-[1.5]">
      <VerticalScrollItem
        v-for="paragraph in slidingParagraphs"
        :text="paragraph.text"
        :text-colour-css="paragraph.textColourCss"
        :class="paragraph.class"
      />
    </VerticalScrollAnimation>
  </div>
</template>

<script setup>
import VerticalScrollAnimation from '@components/VerticalScrollAnimation.vue';
import VerticalScrollItem from '@components/VerticalScrollItem.vue';
import { computed } from 'vue';

const props = defineProps({
  slidingParagraphs: {
    type: Array,
    required: true,
  },
  backgroundImage: {
    type: String,
    default: '',
  },
});

const background = computed(() => {
  if (props.backgroundImage) {
    return `background-image: url(${props.backgroundImage})`;
  }
  return 'background:ol-cream';
});
</script>

<style scoped></style>
