<template>
  <section class="review-section">
    <div
      class="flex flex-col justify-center items-center gap-5 lg:gap-12 max-w-content mx-auto"
    >
      <h2 class="text-lg md:text-xl 2xl:text-3xl self-start">
        {{ header }}
      </h2>
      <div class="review-section__card-container">
        <Card
          v-for="review in reviews"
          :key="review.title"
          class="flex flex-col flex-1 gap-8 justify-between bg-white !p-8"
        >
          <div class="flex flex-col gap-2">
            <h4 v-if="review.header" class="text-2xl font-bold">
              {{ review.header }}
            </h4>
            <p class="text-lg text-ol-dark-100">{{ review.body }}</p>
          </div>
          <div class="flex justify-between self-stretch items-end max-h-14">
            <p
              v-if="width > 640 && review.author"
              class="text-ol-dark-50 font-bold"
            >
              {{ review.author }}
            </p>
            <div v-if="review.logoUrl">
              <img
                class="max-h-12"
                :src="review.logoUrl"
                :alt="review.logoAltText"
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  </section>
</template>

<script setup>
import Card from '@components/Card/Card.vue';
import { useWindowSize } from '@vueuse/core';

const props = defineProps({
  header: {
    type: String,
    default: '',
  },
  reviews: {
    type: Object,
    required: true,
    default: [],
  },
});

const { width } = useWindowSize();
</script>

<style lang="scss" scoped>
.review-section {
  @apply bg-ol-dark-ink-200
  text-white
  py-20 lg:py-28 2xl:py-36
  px-5 xs:px-8 md:px-7 lg:px-12 xl:px-36
  rounded-3xl;

  &__card-container {
    @apply flex flex-col xl:flex-row lg:flex-wrap gap-y-5 gap-x-12 text-ol-dark-ink-200;
  }
}
</style>
