<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
  >
    <path
      d="M8.68539 14.5437L7.97949 17.0143L7.27359 14.5437C6.90282 13.246 5.88845 12.2316 4.59077 11.8608L2.12012 11.1549L4.59077 10.449C5.88846 10.0783 6.90282 9.06391 7.27359 7.76622L7.97949 5.29557L8.68539 7.76622C9.05616 9.06391 10.0705 10.0783 11.3682 10.449L13.8389 11.1549L11.3682 11.8608C10.0705 12.2316 9.05616 13.246 8.68539 14.5437Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.0167 8.30298L15.792 9.20182L15.5673 8.30299C15.3049 7.25332 14.4853 6.43374 13.4356 6.17132L12.5368 5.94661L13.4356 5.72191C14.4853 5.45949 15.3049 4.63991 15.5673 3.59024L15.792 2.69141L16.0167 3.59024C16.2791 4.63991 17.0987 5.45949 18.1484 5.72191L19.0472 5.94661L18.1484 6.17132C17.0987 6.43374 16.2791 7.25332 16.0167 8.30298Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.8321 18.5918L14.4899 19.6185L14.1477 18.5918C13.9533 18.0086 13.4956 17.551 12.9124 17.3565L11.8857 17.0143L12.9124 16.6721C13.4956 16.4777 13.9533 16.02 14.1477 15.4368L14.4899 14.4102L14.8321 15.4368C15.0265 16.02 15.4842 16.4777 16.0674 16.6721L17.0941 17.0143L16.0674 17.3565C15.4842 17.551 15.0265 18.0086 14.8321 18.5918Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script></script>
