<template>
  <OLAboveFold
    :header="heroSection.header"
    header-class="w-full lg:w-7/12"
    :sub-header="heroSection.subHeader"
    sub-header-class="w-full lg:w-11/12"
    :cta-text="heroSection.ctaText"
    :caption="heroSection.caption"
    :hero-link="route('life.welcome')"
    :fold-bg-colour="heroSection.foldBgColour"
    :pre-call-us="ProudlyFeaturedIn"
    :image="heroSection.image"
    mobile-cta-bottom-padding="pb-14 lg:pb-0"
  />

  <BrochureSteps
    :header="stepsSection.title"
    :steps="stepsSection.steps"
    cta-text="Get your quote"
    :cta-link="route('life.welcome')"
  />
  <LineGraphSection
    :heading="graphSection.heading"
    :description="graphSection.description"
  />
  <OLIconCards
    class="bg-ol-secondary-20"
    :with-cta="true"
    :header="perks.header"
    :sub-header="perks.subHeader"
    :cards="perks.benefits"
  />
  <SamsStory :title="samSection.title" :body="samSection.body" />
  <OLTrustpilotReviews />
  <OLFaqs heading="FAQs – Your Guide to Online Life Insurance" :faqs="faqs" />
  <SectionCta />
</template>

<script setup>
import ProudlyFeaturedIn from '@components/Brochure/ProudlyFeaturedIn.vue';
import OLAboveFold from '@components/OLAboveFold.vue';
import LineGraphSection from '@components/Brochure/LineGraphSection.vue';
import BrochureSteps from '@components/Brochure/BrochureSteps.vue';
import OLIconCards from '@components/Brochure/OLIconCards.vue';
import {
  BriefcaseIcon,
  ClipboardDocumentIcon,
  IdentificationIcon,
  RocketLaunchIcon,
} from '@heroicons/vue/24/outline/index.js';
import OLFaqs from '@components/OLFaqs.vue';
import SectionCta from '@components/Footer/SectionCta.vue';
import OLTrustpilotReviews from '@components/Brochure/OLTrustpilotReviews.vue';
import SamsStory from '@components/SamsStory.vue';

const props = defineProps({
  faqs: {
    type: Array,
    required: true,
  },
});

/** AboveFold hero section data */
const heroSection = {
  header: 'Life Insurance.\nFor the 21st Century.',
  subHeader:
    'Life Insurance shouldn’t have to be complicated. That’s why we’ve made our online Life Insurance simple and easy, so you can get back to living life. How it should be.',
  ctaText: 'Get a quote',
  caption: 'Our Head of Technology, Andrea, with his late Dad',
  foldBgColour: 'bg-ol-secondary-50',
  image: {
    url: new URL(
      '/public/theme/brochure/images/hero_images/life_insurance_hero.avif',
      import.meta.url
    ),
    alt: 'Life Insurance Hero Image',
  },
};

const graphSection = {
  heading: 'Get Life Insurance today. Thank us tomorrow.',
  description: `Your monthly Life Insurance premium generally increases the older you
            are. If you know you want Life Insurance in the future, don't hold
            your breath. The sooner you sort it the more likely you'll be to
            secure a cheaper monthly payment (premium), saving you money over
           time.`,
};

const stepsSection = {
  title: 'Online Life Insurance easy as 1, 2, 3 ',
  steps: [
    {
      name: 'Answer a few simple questions.',
      description:
        "No doctor visits, tests, or forms—just answer a few health questions and you're set.",
    },
    {
      name: 'Choose a policy that works for you.',
      description:
        'One size doesn’t fit all. We let you choose what kind of cover you want, so that’s it tailored to you.',
    },
    {
      name: 'Kick back and relax. You’re covered.',
      description:
        'You can rest easy knowing you’ve looked after the people you care about.',
    },
  ],
};

const samSection = {
  title: 'Life Insurance matters. We know because we’ve been there.',
  body: `In 2016, our founder Sam’s mum died in a car accident. It was tough enough without all of the admin, bills, and paperwork that came with it. \n\nThat’s why we started Octopus Legacy. To make it easier for you to get this stuff sorted way ahead of time, because it makes a world of difference when it matters. We know because we’ve been there.`,
};

const perks = {
  header: 'Online Life insurance for an online world',
  subHeader:
    'Life Insurance simple and easy, so you can get back to living life',
  benefits: [
    {
      title: 'You’ll be done in minutes',
      icon: RocketLaunchIcon,
      paragraph:
        'On average it takes 15 minutes to complete your application. It couldn’t be easier.',
    },
    {
      title: 'No medical exams',
      icon: IdentificationIcon,
      paragraph:
        'Our online application is sort-from-the-sofa friendly. We know life is busy and we think Life Insurance should work around you.',
    },
    {
      title: 'You get the ease and security',
      icon: BriefcaseIcon,
      paragraph:
        'We work with our established partners to bring you policies that will stand the test of time. They have 60+ years experience of insuring people like you.',
    },
    {
      title: 'All your end of life admin',
      icon: ClipboardDocumentIcon,
      paragraph:
        'Get Life Insurance with us and you’ll get a FREE Last Will & Testament and Lasting Power of Attorney.',
    },
  ],
};
</script>

<style scoped></style>
