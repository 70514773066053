<template>
  <OLAboveFold
    :header="heroSection.header"
    header-class="w-full lg:w-7/12"
    :sub-header="heroSection.subHeader"
    sub-header-class="w-full lg:w-11/12"
    :cta-text="heroSection.ctaText"
    :caption="heroSection.caption"
    :hero-link="charityHubspotLink"
    :fold-bg-colour="heroSection.foldBgColour"
    :image="heroSection.image"
    mobile-cta-bottom-padding="md:pb-4 lg:pb-0"
    :logos="heroSection.logos"
  />
  <Intro :link-to="charityHubspotLink" />
  <BrochureSteps
    :header="stepsSection.header"
    :sub-header="stepsSection.subHeader"
    :steps="stepsSection.steps"
    :with-extra-padding="true"
  />
  <OLCtaSection
    header="Want help reaching your legacy goals"
    content="Find out more about partnering with Octopus Legacy today."
    :button-route="charityHubspotLink"
    button-text="Arrange a call"
    class="!mb-0"
  />
  <Stats />
  <OLReviews
    header="Don’t listen to us. Listen  to our partners. "
    :reviews="reviews"
  />
  <SamsStory :title="samSection.title" :body="samSection.body" />
  <SectionCta />
</template>

<script setup>
import BrochureSteps from '@components/Brochure/BrochureSteps.vue';
import OLAboveFold from '@components/OLAboveFold.vue';
import OLCtaSection from '@components/OLCtaSection.vue';
import SamsStory from '@components/SamsStory.vue';
import SectionCta from '@components/Footer/SectionCta.vue';
import Stats from '@components/Brochure/Charities/Stats.vue';
import Intro from '@components/Brochure/Charities/Intro.vue';
import OLReviews from '@components/Brochure/OLReviews.vue';

const charityHubspotLink = window.config.charities_meeting;

const reviews = [
  {
    body: '“Really easy to set up and the team have been incredibly supportive...as a result of our partnerships over 40% of the people who’ve made their will online have left a legacy gift.”',
    logoUrl: new URL(
      '/public/theme/brochure/images/charities/featured_logos/st-barnabas-logo.png',
      import.meta.url
    ),
    logoAltText: 'St Barnabas Logo',
  },
  {
    body: '“The Octopus Legacy team have been so helpful in the preparation, planning and running of the trial offer for RHS staff & volunteers. The platform itself has proved to be user friendly & the data has given a really useful insights in how to plan this aspect of our legacy giving programme moving forward”',
    logoUrl: new URL(
      '/public/theme/brochure/images/charities/featured_logos/RHS-logo.png',
      import.meta.url
    ),
    logoAltText: 'RHS Logo',
  },
  {
    body: '“When choosing to partner with Octopus Legacy their payment model was a key factor. Their ‘pay per pledge’ model is more cost-effective for us”',
    logoUrl: new URL(
      '/public/theme/brochure/images/charities/featured_logos/tear-fund-logo.png',
      import.meta.url
    ),
    logoAltText: 'Tear Fund Logo',
  },
];

/** AboveFold hero section data */
const heroSection = {
  header: 'Increase your future legacy income',
  subHeader:
    'With our easy-to-use free will services and expert marketing support.',
  ctaText: 'Work with us',
  caption: 'Our Lead Engineer, Ben’s late step Dad',
  foldBgColour: 'bg-ol-secondary-50',
  image: {
    url: new URL(
      '/public/theme/brochure/images/hero_images/charities_hero.avif',
      import.meta.url
    ),
    alt: 'Charities Hero Image',
  },
  logos: [
    {
      url: new URL(
        '/public/theme/brochure/images/charities/featured_logos/tearfund_logo.svg',
        import.meta.url
      ),
      alt: 'Tearfund Logo',
    },
    {
      url: new URL(
        '/public/theme/brochure/images/charities/featured_logos/rhs_logo.svg',
        import.meta.url
      ),
      alt: 'RHS Logo',
    },
    {
      url: new URL(
        '/public/theme/brochure/images/charities/featured_logos/ndcs_logo.svg',
        import.meta.url
      ),
      alt: 'NDCS Logo',
    },
  ],
};

const stepsSection = {
  header: 'We’re here to help you and your supporters.',
  subHeader:
    'We work with charities of all shapes and sizes across the UK to help them raise millions of pounds in legacy pledges through our free will writing services. We offer you:',
  steps: [
    {
      name: 'A complete will writing service and a clear call to action.',
      description:
        'Whilst +80% of supporters write their will online, one size doesn’t fit all. We offer online, over-the-phone and face-to-face services.',
    },
    {
      name: 'A tailored supporter journey to keep you front of mind.',
      description:
        'Our journey makes it easy for supporters to leave you a gift and to opt-in to charity communication. Helping you improve your pledge rate and stewardship.',
    },
    {
      name: 'Easy-to-use tracking tools to help measure results.',
      description:
        'With real-time data reporting, you’ll get detailed insights on gifts in wills and supporter demographics at the click of a button.',
    },
    {
      name: 'On-hand legacy marketing support.',
      description:
        "You'll get 1:1 marketing support from experts, access to our online community, and to our ready-to-go marketing campaigns throughout the year.",
    },
  ],
};

const samSection = {
  title: 'Loss made easier. By people who’ve been through it.',
  body: 'Sam founded Octopus Legacy after his mum died in 2016. We’ve experienced first-hand how chaotic and overwhelming loss can be.\n\nOur mission is to make your experience of loss easier. That is why we built the support hubs, they’re the tool we wished we’d had.',
};
</script>

<style scoped></style>
