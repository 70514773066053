<template>
  <div class="border-ol-dark-20 border-b last:border-b-0 py-5 xs:py-6 sm:py-8">
    <div
      class="flex items-center justify-between cursor-pointer gap-4 flex-1"
      @click="handleToggle"
    >
      <h3 class="text-base xs:text-lg sm:text-2xl w-9/10 text-ol-dark-ink-200">
        <slot name="header" />
      </h3>
      <PlusCircle
        class="transition-transform duration-300 ease-out rotate-0 origin-center"
        :class="{ 'rotate-45': status }"
      />
    </div>
    <div
      ref="accordion-panel-body"
      class="overflow-hidden pt-3 transition-all duration-300 ease-out max-h-0 sm:pr-12"
    >
      <div class="body-copy">
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<script>
import PlusCircle from '@components/Icon/PlusCircle.vue';

export default {
  components: { PlusCircle },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      status: false,
    };
  },
  mounted() {
    if (this.status) {
      this.$refs['accordion-panel-body'].style.maxHeight =
        `${this.$refs['accordion-panel-body'].scrollHeight}px`;
    }
  },
  methods: {
    handleToggle() {
      const element = this.$refs['accordion-panel-body'];
      this.status = !this.status;

      if (element.style.maxHeight) {
        element.style.maxHeight = null;
      } else {
        element.style.maxHeight = `${element.scrollHeight}px`;
      }
    },
  },
  watch: {
    open: {
      handler(newValue) {
        if (newValue) {
          this.status = newValue;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.body-copy {
  @apply text-dark-ashes-100 md:text-lg;
  :slotted(p) {
    @apply mt-4;
  }

  :slotted(ul) {
    list-style: disc;
    @apply mb-4 ml-6;

    > li {
      @apply py-0.5;
    }
  }

  :slotted(a) {
    @apply text-ol-purple;
  }
}
</style>
