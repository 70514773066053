<template>
  <OLAboveFold
    :header="heroSection.header"
    header-class="w-full lg:w-1/2"
    :sub-header="heroSection.subHeader"
    :cta-text="heroSection.ctaText"
    :caption="heroSection.caption"
    hero-link="https://meetings.hubspot.com/dylan151/inbound"
    :fold-bg-colour="heroSection.foldBgColour"
    :image="heroSection.image"
    mobile-cta-bottom-padding="md:pb-4 lg:pb-0"
  />
  <OLIconCards
    :header="cards.header"
    :sub-header="cards.subHeader"
    :cards="cards.cards"
    button-text="Create a support hub"
    :button-route="route('support-hubs.create')"
  />
  <YouAndYourFamilies />
  <OLCtaSection
    header="Offer Support Hubs to your families today."
    content="Find out more about partnering with Octopus Legacy today."
    button-route="https://meetings.hubspot.com/dylan151/inbound"
    button-text="Arrange a call"
    class="!mb-0 mt-20 md:mt-28 lg:mt-36"
  />
  <Stats class="py-20 md:py-28 lg:py-36" />
  <!--  <OLTrustpilotReviews class="mt-28 xl:mt-36" />-->
  <OLReviews
    header="Don’t listen to us. Listen to our customers."
    :reviews="reviews"
  />
  <SamsStory :title="samSection.title" :body="samSection.body" />
  <SectionCta />
</template>

<script setup>
import OLAboveFold from '@components/OLAboveFold.vue';
import OLCtaSection from '@components/OLCtaSection.vue';
import OLIconCards from '@components/Brochure/OLIconCards.vue';
import SamsStory from '@components/SamsStory.vue';
import SectionCta from '@components/Footer/SectionCta.vue';
import Stats from '@components/Brochure/FuneralDirectors/Stats.vue';
import YouAndYourFamilies from '@components/Brochure/FuneralDirectors/YouAndYourFamilies.vue';
import {
  BanknotesIcon,
  ClockIcon,
  ComputerDesktopIcon,
  ListBulletIcon,
  PhotoIcon,
  TruckIcon,
} from '@heroicons/vue/24/outline';
import OLReviews from '@components/Brochure/OLReviews.vue';

/** AboveFold hero section data */
const heroSection = {
  header: 'Provide holistic support for your families.',
  subHeader:
    'Compassionate guidance and support to help you honor your loved ones with dignity and care.',
  ctaText: 'Work with us',
  caption: 'Our Charity Partnerships Manager, Suzanne’s late grandparents',
  foldBgColour: 'bg-ol-secondary-50',
  image: {
    url: new URL(
      '/public/theme/brochure/images/hero_images/funeral_directors_hero.avif',
      import.meta.url
    ),
    alt: 'Funeral Directors Hero Image',
  },
};

const reviews = [
  {
    header: 'Octopus Legacy is brilliant',
    body: 'Their service provides a permanent memorial for families to return to for years to come.',
    author: 'Jamie F Knight Funeral Services',
    logoUrl: new URL(
      '/public/theme/brochure/images/funeral-directors/jf-knight-logo.png',
      import.meta.url
    ),
    logoAltText: 'Knight Funeral Services',
  },
  {
    header: 'Our families love Octopus Legacy',
    body: 'It gives family and friends the chance to share photos and memories, make donations online, and keep each other updated\n',
    author: 'Signature Funerals',
    logoUrl: new URL(
      '/public/theme/brochure/images/funeral-directors/signature-funerals-logo.png',
      import.meta.url
    ),
    logoAltText: 'Signature Funerals Logo',
  },
];

const cards = {
  header: 'Losing someone is overwhelming',
  subHeader:
    'We’re here to help you manage the chaos.  Our support hub will support you in the run up to a funeral and long after. ',
  cards: [
    {
      title: 'Share memories',
      icon: PhotoIcon,
      paragraph: 'Share photos and messages to celebrate your loved one.',
    },
    {
      title: 'Share funeral information',
      icon: ComputerDesktopIcon,
      paragraph: 'All in one place so you can focus on what’s important.',
    },
    {
      title: 'Create to-do lists',
      icon: ListBulletIcon,
      paragraph: "Delegate tasks so you can focus on what's important.",
    },
    {
      title: 'Space out deliveries',
      icon: TruckIcon,
      paragraph: 'To avoid being swamped by flower and food deliveries.',
    },
    {
      title: 'Raise money for charity',
      icon: BanknotesIcon,
      paragraph: 'In memory of your loved one & the causes they cared for.',
    },
    {
      title: 'Carve out visiting times',
      icon: ClockIcon,
      paragraph: 'As well as time just for you and your closest family.',
    },
  ],
};

const samSection = {
  title: 'Loss made easier. By people who’ve been through it.',
  body: 'Sam founded Octopus Legacy after his mum died in 2016. We’ve experienced first-hand how chaotic and overwhelming loss can be.\n\nOur mission is to make your experience of loss easier. That is why we built the support hubs, they’re the tool we wished we’d had.',
};
</script>

<style scoped></style>
