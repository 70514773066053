<template>
  <section class="will-pricing-section">
    <h3 data-testid="will-pricing-header">
      What are our different types of wills?
    </h3>
    <div>
      <PricingCard
        v-for="(will, index) in willOptions"
        :key="index"
        :product-type="will.title"
        :product-description="will.description"
        :product-price="will.price"
        :product-features="will.features"
        :data-testid="`will-option-${index + 1}`"
      />
    </div>
    <OLButton
      variant="special"
      :link-to="route('will.charity-will.start')"
      data-testid="will-pricing-cta"
    >
      Get Started
      <Arrow />
    </OLButton>
  </section>
</template>

<script setup>
import Arrow from '@components/Icon/Arrow.vue';
import OLButton from '@components/Button/OLButton.vue';
import PricingCard from '@components/Card/PricingCard.vue';

defineProps({
  ctaUrl: {
    type: String,
    default: 'will.typeform-onboarding',
  },
});

const willOptions = [
  {
    title: 'Online',
    description: 'Create your will using our online will platform',
    price: 'Free',
    features: [
      'Reviewed by our expert team',
      'Completed online',
      'For simple estates',
    ],
  },
  {
    title: 'Phone',
    description: 'Prepare your will over the phone or via a video call',
    price: 'Free',
    features: [
      'With an expert will writer',
      'Completed over the phone or via video call',
      'Suitable for most estates',
    ],
  },
  {
    title: 'At Home',
    description: 'Prepare your will during a home visit or in branch',
    price: 'Free',
    features: [
      'With an expert will writer',
      'Completed at home or in person in branch',
      'Suitable for most estates',
    ],
  },
];
</script>

<style lang="scss" scoped>
section.will-pricing-section {
  @apply flex
  flex-col
  items-center
  gap-8 xs:gap-10 lg:gap-14
  w-full lg:!w-9/10
  max-w-content
  bg-[unset]
  my-8;

  > h3 {
    @apply font-bold
    text-2xl xs:text-3xl sm:text-4xl lg:text-5xl
    md:text-center
    w-9/10;
  }

  > div {
    @apply flex
    flex-col lg:flex-row
    justify-center
    gap-4
    w-full xl:w-9/10;
  }
}
</style>
