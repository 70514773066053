<template>
  <section class="contact-us-section">
    <div class="contact-us-content">
      <div class="flex flex-col">
        <div class="flex flex-col sm:flex-row justify-between">
          <div class="sm:w-2/3">
            <slot name="heading">
              <p class="text-xl sm:text-2xl xl:text-3xl 2xl:text-4xl">
                Call us now on
                <span class="font-bold">
                  <a
                    :href="`tel:${phoneNumber}`"
                    data-testid="callback-phone-number"
                  >
                    {{ phonePrint }}.
                  </a>
                </span>
                We’ll be able to support with any task - big or small.
              </p>
              <p class="text-base mt-4 xl:text-xl" data-testid="calls-free">
                All calls are free.
              </p>
              <p class="text-base xl:text-xl" data-testid="calls-opening-hours">
                Lines are open Monday to Friday 9am - 5.30pm
              </p>
            </slot>
          </div>
          <HeadShots class="mt-10 sm:mt-0" :headshots="headshots" />
        </div>
        <div class="flex flex-col mt-10 sm:flex-row gap-3">
          <slot name="cta">
            <OLButton
              class="sm:!w-fit !text-sm xl:!text-base"
              variant="secondary-white"
              @click="openIntercom"
            >
              Chat with us
              <Arrow />
            </OLButton>
            <OLButton
              class="sm:!w-fit !text-sm xl:!text-base"
              variant="secondary-white"
              @click="emailUs"
            >
              Email us
              <Arrow />
            </OLButton>
          </slot>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import OLButton from '@components/Button/OLButton.vue';
import Arrow from '@components/Icon/Arrow.vue';
import HeadShots from '@components/HeadShots.vue';

const props = defineProps({
  headshots: {
    type: Array,
    default: ['karen', 'hayden'],
  },
  phoneNumber: {
    type: String,
    default: window.config.contact_phone_number,
  },
  phonePrint: {
    type: String,
    default: window.config.probate_phone_print,
  },
});

const openIntercom = () => {
  window.Intercom('show');
};

const OurEmail = window.config.contact_email;
const emailUs = () => {
  window.location.href = `mailto:${OurEmail}?subject=Enquiry`;
};
</script>

<style lang="scss" scoped>
section.contact-us-section {
  @apply bg-[unset]
    flex
    justify-center
    w-full
    text-white;

  > div.contact-us-content {
    @apply px-5 xs:px-8 sm:px-10
      py-14 sm:py-12
      bg-ol-dark-ink-200
      rounded-3xl
      w-full
      max-w-content
      lg:p-14;
  }
}
</style>
