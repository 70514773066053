<template>
  <div class="experts-card__headshots" data-testid="experts-card-headshots">
    <img
      v-for="(headshot, index) in headshots"
      :key="index"
      :src="getHeadshot(headshot)"
      :alt="`Photo of ${headshot}`"
    />
  </div>
</template>
<script setup>
const props = defineProps({
  headshots: {
    type: Array,
    default: ['laura', 'june', 'callum'],
  },
});
const getHeadshot = (name) => {
  return new URL(`/public/theme/img/headshots/${name}.jpg`, import.meta.url)
    .href;
};
</script>

<style lang="scss" scoped>
.experts-card__headshots {
  @apply flex
  h-16 md:h-20
  ml-4;

  > img {
    @apply -ml-4 aspect-1 max-h-full rounded-full h-auto border-2 border-white;
  }
}
</style>
