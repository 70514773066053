<template>
  <div
    :class="dataTheme"
    class="bg-transparent rounded-3xl dark:bg-ol-dark-ink-200 py-24 sm:py-32 md:py-36"
  >
    <div class="mx-auto px-5 xs:px-8 sm:px-7 md:px-16 max-w-content">
      <div class="max-w-2xl">
        <h2
          class="text-4xl xl:text-5xl font-bold text-ol-dark-ink-200 dark:text-white sm:text-5xl"
        >
          {{ heading }}
        </h2>
        <p class="mt-6 text-ol-dark-ink-200 dark:text-white text-lg">
          {{ subheading }}
        </p>
      </div>
      <div class="mt-16 max-w-2xl sm:mt-14 lg:mt-20 lg:max-w-none">
        <dl
          class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none"
          :class="`lg:grid-cols-${features.length}`"
        >
          <ShowcaseCard
            v-for="feature in features"
            :key="feature.name"
            :feature="feature"
            dark-mode
          />
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
import ShowcaseCard from '@components/Brochure/homePage/ShowcaseCard.vue';
import { computed } from 'vue';

const props = defineProps({
  isDark: {
    type: Boolean,
    default: false,
  },
  features: {
    type: Array,
    default: () => [],
  },
  heading: {
    type: String,
    default: '',
  },
  subheading: {
    type: String,
    default: '',
  },
});

const dataTheme = computed(() => (props.isDark ? 'dark' : 'light'));
</script>

<style scoped></style>
