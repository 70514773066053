<template>
  <HeroSection
    :typeform="typeform"
    :cta-copy="ctaCopy"
    :utm-params="utmParams"
  />
  <BelowFold>
    When someone we love dies, we all need time to grieve. But death can come
    with a lot of hard work. We're here to help take some of the stress off your
    plate.
  </BelowFold>
  <NeedProbate />
  <ServicesSection
    :header="services.header"
    :sub-header="services.subHeader"
    :services="services.services"
  />
  <ScenarioSection
    :header="scenarios.header"
    :description="scenarios.description"
    :scenarios="scenarios.scenarios"
  />
  <ExpertsCard />
  <AdditionalServices />
  <OLFaqs heading="FAQs: Your guide to probate" :faqs="faqs" />
  <SectionCta />
</template>

<script setup>
import AdditionalServices from '@components/Probate/AdditionalServices.vue';
import BelowFold from '@components/Brochure/BelowFold.vue';
import ExpertsCard from '@components/Probate/ExpertsCard.vue';
import HeroSection from '@components/Probate/HeroSection.vue';
import NeedProbate from '@components/Probate/NeedProbate.vue';
import ScenarioSection from '@components/Brochure/ScenarioSection.vue';
import SectionCta from '@components/Footer/SectionCta.vue';
import ServicesSection from '@components/Brochure/ServicesSection.vue';
import OLFaqs from '@components/OLFaqs.vue';

const props = defineProps({
  typeform: {
    type: Object,
    required: true,
  },
  ctaCopy: {
    type: Object,
    required: true,
  },
  utmParams: {
    type: Object,
    required: true,
  },
  faqs: {
    type: Object,
    required: true,
  },
});

const services = {
  header: "Probate that's tailored to you",
  subHeader:
    'We can help you apply for and get a Grant of Probate, or take you from start to finish, depending on what you need.',
  services: [
    {
      header: 'Grant of Probate',
      subHeader:
        'Apply for Grant of Probate. Get legal permission to sort out the estate.',
      bulletPoints: [
        'Work out what inheritance tax you need to pay',
        'Work out if you need to complete an inheritance tax account and help you submit one',
        'Apply for Grant of Probate',
        'Keep you updated throughout',
        'Deliver the Grant of Probate to you',
      ],
      prices: [
        { title: 'No Inheritance tax forms:', value: 585 },
        { title: 'With Inheritance tax forms:', value: 1290 },
      ],
      finePrint:
        'Prices are fixed, including VAT and excluding the £300 probate registry fee and £1.50 per copy of the Grant of Probate.',
      ctaText: 'Get Started',
    },
    {
      header: 'Full estate administration',
      subHeader:
        'From applying for the Grant of Probate to sorting out the whole estate.',
      bulletPoints: [
        'Work out what inheritance tax you need to pay',
        'Work out if you need to complete an inheritance tax account and help you submit one',
        'Apply for Grant of Probate',
        'Keep you updated throughout',
        'Deliver the Grant of Probate to you',
        'Value your estate',
        'Settle any remaining debts',
        'Distribute the estate in accordance with the will or intestacy rules',
      ],
      prices: [{ title: 'From:', value: 2730 }],
      finePrint:
        'Prices are quoted in advance based on individual circumstances. including VAT and excluding the £300 probate registry fee and £1.50 per copy of the Grant of Probate.',
      ctaText: 'Get Started',
      linkToPrices: '#scenario-section',
    },
  ],
  typeform: props.typeform,
  utmParams: props.utmParams,
};

const scenarios = {
  header: 'Full estate admin fees - done differently',
  description:
    "We will always share a fixed fee quoted up front, based on the information that you provide. This means you know exactly what you're signing up for. Your quote will be tailored to you - based on the amount of work involved, rather than solely on the value of the estate.",
  scenarios: [
    {
      header: 'Simple estate',
      value: 2730,
      bullets: [
        '1 bank account/ISA',
        '1 share/investment account',
        '1 credit card',
        '1 beneficiary',
      ],
    },
    {
      header: 'Property to sell',
      value: 4882.5,
      bullets: [
        '1 property to sell',
        '3 bank accounts/ISAs',
        '2 credit cards',
        '4 beneficiaries',
      ],
    },
    {
      header: 'Property, stocks and shares',
      value: 6352.5,
      bullets: [
        '1 property to sell',
        '3 bank accounts/ISAs',
        '4 share/investment accounts',
        '2 credit cards',
        '6 beneficiaries',
        'Inheritance tax forms to complete',
      ],
    },
    {
      header: 'Complex estate',
      value: 11182.5,
      bullets: [
        '2 properties to sell (one foreign)',
        '5 bank accounts/ISAs',
        '4 share/investment accounts',
        '2 foreign bank accounts',
        '6 beneficiaries',
        'Taxable estate',
      ],
    },
  ],
};
</script>

<style scoped></style>
